<template lang="pug">
div 
  .card.mb-4
    .card-header.d-flex.justify-content-between.align-items-center
      h4.mb-0 Bestellung
    .card-body
      .row
        .col-12.col-sm-4
          label.form-label(for="ticket_category") Kategorie *
          select#ticket_category.form-select(@change="changeCategory" v-model="form.ticket_category", placeholder="Kategorie *")
            option(:value="p.id" v-for="p in products") {{p.name}}
            option(value="G") Gemischte Kategorien

        .col-12.col-sm-4
          label.form-label(for="quantity") Kartenanzahl *
          input.form-control#quantity(@input="quantityUpdate" v-model="form.quantity" type="number" min="0" placeholder="Kartenanzahl")
        .col-12.col-sm-4
          label.form-label(for="ticket_count_children") Kinderanzahl *
          input.form-control#ticket_count_children(@input="quantityUpdate" v-model="form.ticket_count_children" type="number" min="0" placeholder="Kinderanzahl")
      .row.mt-4(v-if="form.ticket_category == 'G'")
        .col(v-for="p in products")
          label.form-label(for="p.id") {{p.name}}
          input.form-control#menu_count_classic(v-model="categoryCounts[p.id]" type="number" min="0" :placeholder="p.name")
        .col-12: .text-center.mt-1: small: strong {{categoryCountAll}}/{{form.quantity+form.ticket_count_children}} ({{form.quantity+form.ticket_count_children-categoryCountAll}})
      .row.mt-4
        .col-12.col-sm-12
          label.form-label Veranstaltungstag *
          vue-date-picker(
            @closed="setDate"
            v-model="form.date"
            locale="de"
            :enable-time-picker="false"
            :month-change-on-scroll="false"
            :auto-apply="true"
            :clearable="false"
            :start-date="startDate"
            :min-date="startDate"
            :max-date="maxDate"
            :allowed-dates="allowedDates"
            :format="format"
            placeholder="Bitte auswählen"
          )
        //- .col-12.col-sm-4: .mb-3
        //-   label.form-label(for="discount") Rabatt
        //-   .input-group
        //-     .input-group-text -
        //-     input.form-control(type="number" min="0", placeholder="Rabatt", v-model="form.discount", id="discount")
        //-     .input-group-text.d-none.d-sm-inline €
      .row.mb-4(v-if="trHtml"): .col-12
        table.table
          tr
            th Datum
            th GL
            th BT
            th K1
            th K2
            th K3
            th Gesamt
          tr(v-html="trHtml")
        .alert.alert-danger.mt-4(v-if="isOverBooked()")
          h4.text-danger Veranstaltung wurde überbucht!
      .row.mt-4
        .col-12.col-sm-4
          label.form-label(for="accompanying_person_count") Davon Begleitper.
          input.form-control#accompanying_person_count(v-model="form.accompanying_person_count" type="number" min="0" placeholder="Davon Begleitperson")
        .col-12.col-sm-4
          label.form-label(for="bus_driver_count") Davon Busfahrer
          input.form-control#bus_driver_count(v-model="form.bus_driver_count" type="number" min="0" placeholder="Davon Busfahrer")
        .col-12.col-sm-4
          label.form-label(for="wheelchair_count") Davon Rollstuhl
          input.form-control#wheelchair_count(v-model="form.wheelchair_count" type="number" min="0" placeholder="Davon Rollstuhl")

      .row.mt-4
        .col-12.col-sm-6
          label.form-label(for="employee_count") Davon Mitarbeiter
          input.form-control#employee_count(v-model="form.employee_count" type="number" min="0" placeholder="Davon Mitarbeiter")
        .col-12.col-sm-6
          label.form-label(for="artist_count") Davon Künstler
          input.form-control#artist_count(v-model="form.artist_count" type="number" min="0" placeholder="Davon Künstler")


      .row.mt-4
        .col-12.col-sm-6
          label.form-label(for="menu_count_classic") Klassische Menüs *
          input.form-control#menu_count_classic(@input="menuUpdate" v-model="form.menu_count_classic" type="number" min="0" placeholder="Klassische Menüs")
        .col-12.col-sm-6
          label.form-label(for="menu_count_veggie") Vegetar. / Vegane Menüs *
          input.form-control#menu_count_veggie(@input="veganUpdate" v-model="form.menu_count_veggie" type="number" min="0" placeholder="Vegetar. / Vegane Menüs")
        .col-12.mt-4
          label.form-label(for="final_message") Kommentar von/an Kunden
          textarea.form-control#final_message(v-model="form.final_message" placeholder="Kommentar von/an Kunden")
        .col-12
          .form-check.form-switch.mt-4
            input.form-check-input(id="intolerance_active" type="checkbox" v-model="form.intolerance_active")
            label.form-check-label(for="intolerance_active") Gäste mit Unverträglichkeiten eintragen    
  .card.mb-4(v-if="form.intolerance_active")
    .card-header.d-flex.justify-content-between.align-items-center
      h4.mb-0 Unverträglichkeiten
    .card-body
      .mb-3(v-for="(i, n) in form.intolerances")
        .repeater-wrapper.pt-0.pt-md-4
          .d-flex.border.rounded.position-relative.pe-0
            .w-100
              .row.m-0.p-3
                .col-12.col-sm-8: h5.mt-2 Gast {{n+1}}
                .col-12.col-sm-4
                  select#ticket_category.form-select(v-model="i.type")
                    option(value="classic") Klassisch
                    option(value="veggie") Vegetarisch
              .row.w-100.p-3.pt-0
                .col-12.col-sm-6: .form-check.form-switch
                  input.form-check-input(:id="'gluten'+n" type="checkbox" v-model="i.gluten")
                  label.form-check-label(:for="'gluten'+n") Glutenallergie
                .col-12.col-sm-6: .form-check.form-switch
                  input.form-check-input(:id="'nuts'+n" type="checkbox" v-model="i.nuts")
                  label.form-check-label(:for="'nuts'+n") Nussallergie
                .col-12.col-sm-6: .form-check.form-switch
                  input.form-check-input(:id="'fructose'+n" type="checkbox" v-model="i.fructose")
                  label.form-check-label(:for="'fructose'+n") Fructoseintoleranz
                .col-12.col-sm-6: .form-check.form-switch
                  input.form-check-input(:id="'other'+n" type="checkbox" v-model="i.other")
                  label.form-check-label(:for="'other'+n") Sonstiges
                .col-12.mt-4
                  label.form-label(for="final_message") Sonstige Unverträglichkeiten
                  textarea.form-control#final_message(v-model="i.other_details" placeholder="Sonstige Unverträglichkeiten")
            .d-flex.flex-column.align-items-center.justify-content-between.border-start.p-2(v-if="n != 0")
              svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 24px; height: 24px; cursor: pointer" @click="removeIntolerance(i.id)"): path(fill="currentColor" d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z")
      .text-center: button.btn.btn-primary(@click="addIntolerance") Gast mit Unverträglichkeiten hinzufügen
  .card.mb-4(v-if="product")
    .card-header.d-flex.justify-content-between.align-items-center
      h4.mb-0 Zusatzleistung
    .card-body
      .row
        .col-12.col-sm-6
          label.form-label(for="drinks_flat_rate_product_id") Getränkepauschale *
          select#drinks_flat_rate_product_id.form-select(v-model="form.drinks_flat_rate_product_id", placeholder="Getränkepauschale *")
            option(:value="p.id" v-for="p in product.drinks_flat_rates") {{p.name}}
        .col-12.col-sm-6
          .form-check.form-switch(v-for="p in product.welcome_drinks" style="margin-top: 35px;")
            input.form-check-input(:id="'wd'+p.id" type="checkbox" :value="p.id" :true-value="p.id" false-value="" v-model="form.welcome_drinks_product_id")
            label.form-check-label(:for="'wd'+p.id") {{p.name}}
          .form-check.form-switch(style="margin-top: 10px;" v-if="form.welcome_drinks_product_id && (!editmode || !_form.welcome_drinks_product_id)")
            input.form-check-input(id="welcome_drinks_product_free" type="checkbox" :value="true" :true-value="true" :false-value="false" v-model="form.welcome_drinks_product_free")
            label.form-check-label(for="welcome_drinks_product_free") 
              | Begrüßungsgetränk 
              strong kostenfrei

      .row.mt-3
        .col-12.col-sm-6(v-for="p in product.extras")
          .form-check.form-switch
            input.form-check-input(:id="p.key_name+p.id" type="checkbox" :value="p.id" :true-value="p.id" false-value="" v-model="form[p.key_name+'_product_id']")
            label.form-check-label(:for="p.key_name+p.id") {{p.name}}
          .mb-4(v-if="form[p.key_name+'_product_id'] && p.countable")
            label.form-label(:for="p.key_name+'_count'") Anzahl
            input.form-control(:id="p.key_name+'_count'" v-model="form[p.key_name+'_count']" type="number" min="0" placeholder="Anzahl")
  .alert.alert-warning(v-if="mode == 'rebook'")
    h4.text-warning Änderung löst Stornierung und Neubuchung aus. 
    p Der bisherige Auftrag des Kunden wird automatisch Storniert und neu angelegt. Der Kunde erhält (falls aktiviert) eine Benachrichtigung (mit Beleg) über die Stornierung und über die Neubuchung.
  .alert.alert-info(v-if="mode == 'upgrade'")
    h4.text-info Änderung löst Nachbuchung aus. 
    p Der bisherige Auftrag des Kunden bleibt bestehen und für die Zusatzleistung wird eine gesonderte Rechnung angelegt. Dre Kunde erhält (falls aktiviert) eine Benachrichtigung die Nachbuchung.
  #dataAccordion.accordion.mb-4
    .card.accordion-item
      h2.accordion-header
        button.accordion-button.collapsed(aria-controls='dataAccordionOne' aria-expanded='false' data-bs-target='#dataAccordionOne' data-bs-toggle='collapse' type='button') Individuelle Preise
      #dataAccordionOne.accordion-collapse.collapse(data-bs-parent='#dataAccordion')
        .card-body: .row
          .col-12.col-sm-6: .mb-3
            label.form-label(for="special_price") Kategorie Preis
            .input-group
              input.form-control(type="number" min="25", placeholder="Kategorie Preis", v-model="form.special_price", id="special_price", @input="setTicketPrice")
              .input-group-text.d-none.d-sm-inline €
            .alert.alert-danger.mt-3(v-if="form.special_price && form.special_price < 25") Muss mindesten 25,00 € betragen (Catering-Anteil)
          .col-12.col-sm-6: .mb-3
            label.form-label(for="welcome_drinks_special_price") Begrüßungsgetränk Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Begrüßungsgetränk Preis", v-model="form.welcome_drinks_special_price", id="welcome_drinks_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="drinks_flat_rate_special_price") Getränkepauschale Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Getränkepauschale Preis", v-model="form.drinks_flat_rate_special_price", id="drinks_flat_rate_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="card_special_price") Geschenkkarte Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Geschenkkarte Preis", v-model="form.card_special_price", id="card_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="card_shipping_special_price") Geschenkkarte Versand Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Geschenkkarte Versand Preis", v-model="form.card_shipping_special_price", id="card_shipping_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="parking_special_price") Parken Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Parken Preis", v-model="form.parking_special_price", id="parking_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="wardrobe_special_price") Garderobe Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Garderobe Preis", v-model="form.wardrobe_special_price", id="wardrobe_special_price")
              .input-group-text.d-none.d-sm-inline €
          .col-12.col-sm-6: .mb-3
            label.form-label(for="catering_special_price") Catering Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Catering Preis", v-model="form.catering_special_price", id="catering_special_price")
              .input-group-text.d-none.d-sm-inline €
        .card-body: .row
          .col-12.col-sm-6: .mb-3
            label.form-label(for="extra_position_title") Individuelle Position Bezeichung
            .input-group
              input.form-control(type="text" placeholder="Individuelle Position Bezeichung", v-model="form.extra_position_title", id="extra_position_title")
          .col-12.col-sm-6: .mb-3
            label.form-label(for="extra_position_price") Individuelle Position Preis
            .input-group
              input.form-control(type="number" min="0", placeholder="Individuelle Position Preis", v-model="form.extra_position_price", id="extra_position_price")
              .input-group-text.d-none.d-sm-inline €
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker'; 
import '@vuepic/vue-datepicker/dist/main.css'
export default {
  components: {
    VueDatePicker
  },
  props: [
    "products",
    "api-id",
    "domainurl",
    "orderItem",
    "domainid",
    "date",
    "count",
    "rebook",
    "editmode",
    "quota",
    "quota2",
    "child_order_sum"
  ],
  data () {
    return {
      form: {
        ticket_category: null,
        date: null,
        quantity: 2,
        discount: 0,
        ticket_count_children: 0,
        product_id: null,
        welcome_drinks_product_free: false,
        welcome_drinks_product_id: null,
        drinks_flat_rate_product_id: null,
        wardrobe_product_id: null,
        parking_product_id: null,
        card_product_id: null,
        parking_count: 0,
        card_count: 0,
        bus_driver_count: 0,
        wheelchair_count: 0,
        accompanying_person_count: 0,
        employee_count: 0,
        artist_count: 0,
        menu_count_classic: 2,
        menu_count_veggie: 0,
        final_message: "",
        intolerance_active: false,
        intolerances: [],
        categories_count: {},
        special_price: null,
        welcome_drinks_special_price: null,
        card_special_price: null,
        wardrobe_special_price: null,
        parking_special_price: null,
        card_shipping_special_price: null,
        drinks_flat_rate_special_price: null,
        parking_special_price: null,
        extra_position_price: null,
        extra_position_title: null,
        catering_special_price: null
      },
      mode: "",
      _form: {},
      _orderItem: {},
      categoryCounts: {"BT": 0, "K1": 0, "K2": 0, "K3": 0},
      product: null,
      startDate: new Date(2024, 11, 1),
      maxDate: new Date(2025, 0, 31),
      allowedDates: [new Date(2024, 11, 1), new Date(2025, 0, 1)],
      dates: [],
      loading: true,
      trHtml: null
    }
  },
  mounted () {
    this._orderItem = JSON.parse(JSON.stringify(this.orderItem))
    if(this.child_order_sum && this.child_order_sum != 0.0){
      setTimeout(() => {
        this.form.extra_position_price = parseFloat(this.child_order_sum) * -1.00
        this.form.extra_position_title = "Differenz aus Nachbuchung"
      }, 1000);

    }
  },
  watch: {
    rebook(){    
      this.setMode()
    },
    products(){    
      this.init()
      this.addIntolerance()
      this.$emit('update:order_item', this.form)
    },
    form: {
      handler(){
        if (this.product) this.$emit('update:order_item', this.form)
        if (this.product) this.fetchPreview()
        this.setMode()
      },
      deep: true
    },
    orderItem: {
      handler(){
        if (this.orderItem) {
          this.form = JSON.parse(JSON.stringify(this.orderItem))
          if(this.form.date) this.fetchUtilization()
          this.categoryCounts = this.form.categories_count
          this.fetchDates(() => {
            this.setProduct()
          })
        this._form = JSON.parse(JSON.stringify(this.form))
        if (!this._orderItem.id) this._orderItem = JSON.parse(JSON.stringify(this.orderItem))
        }
      },
      deep: true
    },
    categoryCounts: {
      handler(){
        this.setProduct()
      },
      deep: true
    }
  },
  computed: {
    categoryCountAll(){
      return Object.values(this.categoryCounts).map(x => x || 0).reduce((b, a) => b + a, 0);
    }
  },
  methods: {
    currentCounts(base, category){
      let baseAvailableCounts = 0
      if (base.ticket_category == category){
        baseAvailableCounts = baseAvailableCounts + base.quantity + base.ticket_count_children
      }
      if (base.ticket_category == "G"){
        baseAvailableCounts = baseAvailableCounts + (base.categories_count[category] || this.categoryCounts[category] || 0)
      }
      return baseAvailableCounts
    },
    availableCounts(category){
      if (!this.trHtml) return 0

      const parser = new DOMParser()
      const doc = parser.parseFromString(this.trHtml, 'text/html')
      const targetDiv = doc.querySelector('.available-counts-'+category)

      if (targetDiv) {
        let baseAvailableCounts = parseInt(targetDiv.innerHTML.replace("(", "").replace(")", ""))
        return baseAvailableCounts - this.currentCounts(this.form, category) + this.currentCounts(this._orderItem, category)
      } else {
        return 0
      }
    },
    isOverBooked(){
      if (this.form.ticket_category != "G") {
        return this.availableCounts(this.form.ticket_category) < 0
      } else if (this.availableCounts("BT") < 0 || this.availableCounts("K1") < 0 || this.availableCounts("K2") < 0 || this.availableCounts("K3") < 0) {
        return true
      } else {
        return false
      }
    },
    setMode(){
      let basePrice = parseFloat(this.form.special_price)
      if (basePrice === 0) basePrice = "0"
      if (basePrice) this.form.product_price = basePrice

      if (this.quota && this.quota2) return false
      if (!this.editmode) return false

      let drinks_flat_rate = {}
      let _drinks_flat_rate = {}
      if (this.product) drinks_flat_rate = this.product.drinks_flat_rates.find(x => x.id == this.form.drinks_flat_rate_product_id)
      if (this.product) _drinks_flat_rate = this.product.drinks_flat_rates.find(x => x.id == this._form.drinks_flat_rate_product_id)

      const rebookValues = [
        "quantity",
        "ticket_count_children",
        "product_id",
        "discount",
        "bus_driver_count",
        "accompanying_person_count",
        "employee_count",
        "artist_count",
        "special_price",
        "welcome_drinks_special_price",
        "card_special_price",
        "wardrobe_special_price",
        "parking_special_price",
        "card_shipping_special_price",
        "drinks_flat_rate_special_price",
        "parking_special_price",
        "welcome_drinks_product_free"
      ]

      const productValues = ["welcome_drinks_product_id", "wardrobe_product_id", "parking_product_id", "card_product_id"]
      const countValues = ["parking_count", "card_count"]
      
      if (this.rebook){
        this.mode = "rebook"
      } else if (JSON.stringify(this.form.categories_count) != JSON.stringify(this._form.categories_count)){
        this.mode = "rebook"
      } else if (rebookValues.map(x => this.form[x] != this._form[x]).indexOf(true) != -1){
        this.mode = "rebook"
      } else if (drinks_flat_rate && _drinks_flat_rate && parseFloat(drinks_flat_rate.price) < parseFloat(_drinks_flat_rate.price)){
        this.mode = "rebook"
      } else if (productValues.map(x => this._form[x] && this._form[x] != "" && (!this.form[x] || this.form[x] == '')).indexOf(true) != -1){
        this.mode = "rebook"
      } else if (countValues.map(x => parseFloat(this.form[x]) < parseFloat(this._form[x])).indexOf(true) != -1){
        this.mode = "rebook"
      } else if (drinks_flat_rate && _drinks_flat_rate && parseFloat(drinks_flat_rate.price) > parseFloat(_drinks_flat_rate.price)){
        this.mode = "upgrade"
      } else if (productValues.map(x => this.form[x] && this.form[x] != "" && (!this._form[x] || this._form[x] == '')).indexOf(true) != -1){
        this.mode = "upgrade"
      } else if (countValues.map(x => parseFloat(this.form[x]) > parseFloat(this._form[x])).indexOf(true) != -1){
        this.mode = "upgrade"
      } else {
        this.mode = ""
      }
      this.$emit('update:innerrebook', this.mode == "rebook")


    },
    setDate(){
      this.setProduct()
      this.fetchUtilization()
    },
    fetchUtilization(){
      fetch("/api/ticketshops/order/utilization.json?domain_id="+this.domainid+"&date="+this.form.date).then(x => x.json()).then((data) => {
        this.trHtml = data.html
      })
    },
    changeCategory(){
      this.fetchDates(() => {
        this.setProduct()
      })
    },
    removeIntolerance(id){
      this.form.intolerances = this.form.intolerances.filter(x => x.id != id)
    },
    addIntolerance(){
      const id = (Math.random() + 1).toString(36).substring(7)
      this.form.intolerances.push({id: id, type: "classic", gluten: false, nuts: false, fructose: false, other: false, other_details: ""})
    },
    quantityUpdate(){
      setTimeout(() => {
        this.form.menu_count_classic = this.form.quantity + this.form.ticket_count_children - this.form.menu_count_veggie
        this.setTicketPrice()
      }, 100);
    },
    menuUpdate(){
      this.form.menu_count_veggie = this.form.quantity + this.form.ticket_count_children - this.form.menu_count_classic
    },
    veganUpdate(){
      this.form.menu_count_classic = this.form.quantity + this.form.ticket_count_children - this.form.menu_count_veggie
    },
    init(){
      if (this.products[0]) this.form.ticket_category = this.products[0].id
      setTimeout(() => {
        this.fetchDates(() => {
          if (this.count) {
            this.form.quantity = this.count
            this.formmenu_count_classic = this.count
          }
          if (this.date) {
            this.form.date = this.date
            this.setProduct()
            this.fetchPreview()
            this.fetchUtilization()
          }
        })
      }, 100);
    },
    setProduct(){
      if (this.form.date){
        let date = this.form.date
        if (typeof date.getMonth === 'function') date = date.toISOString()
        if (date.indexOf("T") != -1) date = date.split('T')[0]
        this.product = this.dates.find(x => x.date == date)
        if (this.product){
          this.form.product_id = this.product.id
          this.setTicketPrice()
          if (typeof this.form.intolerances === "string") this.form.intolerances = JSON.parse(this.form.intolerances)
          if (!this.form.drinks_flat_rate_product_id) this.form.drinks_flat_rate_product_id = this.product.drinks_flat_rates[0].id
          this.$emit('update:product', this.product)

        }
      }
    },
    setTicketPrice(){
      let basePrice = parseFloat(this.form.special_price)
      if (basePrice === 0) basePrice = "0"      
      if (this.form.ticket_category == "G") {
        let categories = []
        let price = 0.00
        for (const [key, value] of Object.entries(this.categoryCounts)) {
          if (value != 0) categories.push(value+"x "+key)
          if (this.product.name.indexOf("31.12.") != -1){
            price += value * (basePrice || this.products.find(x => x.id == key).price_silvester)
          }else{
            price += value * (basePrice || this.products.find(x => x.id == key).price)
          }
        }
        price = price.toFixed(2)
        this.form.product_name = "Gemischte Kategorien ("+(categories.join(", ")||"0x BT")+")" + this.product.name
        this.form.product_price = basePrice || (price / (this.form.quantity + this.form.ticket_count_children)).toFixed(2)
        this.form.categories_count = this.categoryCounts
      } else {
        this.form.product_name = this.product.name
        this.form.product_price = (basePrice || this.product.price)
      }
    },
    fetchPreview(){
      const params = {
        date: this.orderItem ? this.orderItem.date : null,
        order_item: this.form,
        api_token: this.apiId
      }
      const options = {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }

      params.order_item.price = params.order_item.product_price

      fetch(this.domainurl+"/api/ticketshop/preview.json", options).then(x => x.json()).then((data) => {
        if (this.form.ticket_category == "G") {
          const i = data.dependent_positions.findIndex(x => x.name == "Kids unter 12 Jahre")
          if (i != -1) data.dependent_positions[i].price = this.form.product_price * this.product.kids_multiplicator
        }
        this.$emit('update:preview', data)
      })       
    },
    fetchDates(callback){
      const params = {
        date: this.orderItem ? this.orderItem.date : null,
        count: this.form.quantity + this.form.ticket_count_children,
        category: this.form.ticket_category,
        api_token: this.apiId
      }
      const options = {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      fetch(this.domainurl+"/api/get/dates.json", options).then(x => x.json()).then((data) => {
        this.loading = false
        this.dates = data.dates
        this.allowedDates = data.dates.map( x => x.date )
        if (data.dates[0]){
          this.startDate = data.dates[0].date
          this.maxDate = data.dates.slice(-1).date
        }
        if (callback) callback()
      })      
    },
    format(date){
      function pad(n, len) {
        return (new Array(len + 1).join('0') + n).slice(-len);
      }

      const day = date.getDate();
      const month = date.getMonth() + 1;
      const year = date.getFullYear();

      return `${pad(day, 2)}.${pad(month, 2)}.${year}`;
    }
  }
}
</script>

<style lang="scss">
</style>