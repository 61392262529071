<template lang="pug">
div 
  div
    a(:href="previewUrl" v-if="previewUrl && previewUrl.endsWith('.pdf')" taget="_blank" style="margin-top: 10px; padding: 5px;") Dokument öffenen
    img(:src="previewUrl" v-else-if="previewUrl" style="height: 100px; max-width: 100%; background: #ccc; padding: 5px;")
  .float-end: svg(xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" style="width: 15px; cursor: pointer" @click="mode=!mode"): path(fill="currentColor" d="M12.89,3L14.85,3.4L11.11,21L9.15,20.6L12.89,3M19.59,12L16,8.41V5.58L22.42,12L16,18.41V15.58L19.59,12M1.58,12L8,5.58V8.41L4.41,12L8,15.58V18.41L1.58,12Z")
  input(class="form-control" type="file" :id="id" @change="uploadHandshake" v-if="mode")
  input(class="form-control" type="text" :id="id" @input="(e) => $emit('update:modelValue', e.target.value)" v-else :value="modelValue")
</template>


<script>
export default {
  components: {
  },
  props: [
    "modelValue",
    "col",
    "record",
    "domain",
    "id"
  ],
  data () {
    return {
      mode: true
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
    previewUrl(){
      if (!this.modelValue || this.modelValue.length == 0) return null
      if (this.modelValue.startsWith("http")) return this.modelValue
      if (this.domain.domain.domain_name.indexOf("localhost") != -1){
        return "http://"+ this.domain.domain.domain_name + this.modelValue
      } else {
        return "https://"+ this.domain.domain.domain_name + this.modelValue
      }
    }
  },
  methods: {
    uploadHandshake(e){
      this.loading = true
      const csrfToken = document.getElementsByName("csrf-token")[0].content
      let options = {
        method: "POST",
        headers: {
          "X-CSRF-Token": csrfToken,
          "Content-Type": "application/json",
          "Accept": "application/json",
        }
      }
      let params = {data: {}}
      options.body = JSON.stringify({col: this.col, record: this.record})

      fetch(window.location.href.split("?")[0]+"/upload.json", options).then(x => x.json()).then((data) => {
        let formData = new FormData();
        formData.append('file', e.target.files[0]); 
        let url = data.upload_url
        if (data.upload_path){
          if (this.domain.domain.domain_name.indexOf("localhost") != -1){
            url = "http://"+ this.domain.domain.domain_name + data.upload_path
          } else {
            url = "https://"+ this.domain.domain.domain_name + data.upload_path
          }
        }
        fetch(url, {body: formData, method: "POST"}).then(x => x.json()).then((innerData) => {
          if (innerData.path) this.$emit('update:modelValue', innerData.path)
        })
      })
    }
  }
}
</script>

<style lang="scss">
</style>