<template lang="pug">
div(style="width: 100%; overflow: hidden;padding-left: 75px;padding-bottom: 100px;")
  .row
    div(style="width: 10%; margin-left: -30px;")
      div(style="transform: rotate(270deg); margin-top: -35px;"): table-element(@action="action" number="80" :data="data" :read_only="read_only")
      div(style="transform: rotate(270deg); margin-top: -45px;"): table-element(@action="action" number="81" :data="data" :read_only="read_only")
    div(style="width: 1%;")
    div(style="width: 8%; margin-left: 8px;")
      table-element(@action="action" number="90" :data="data" :read_only="read_only")
      table-element(@action="action" number="91" :data="data" :read_only="read_only")

    div(style="width: 8%;")
      table-element(@action="action" number="70" :data="data" :read_only="read_only")
      table-element(@action="action" number="71" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      div(style="margin-right: -13px; margin-left: -13px;"): div(style="margin-right: 13px; margin-left: 13px;"): table-element(@action="action" number="60" :data="data" :read_only="read_only")
      table-element(@action="action" number="61" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="50" :data="data" :read_only="read_only")
      table-element(@action="action" number="51" :data="data" :read_only="read_only")
    div(style="width: 10%;")
      table-element(@action="action" number="1" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="40" :data="data" :read_only="read_only")
      table-element(@action="action" number="41" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      div(style="margin-right: -13px; margin-left: -13px;"): div(style="margin-right: 13px; margin-left: 13px;"): table-element(@action="action" number="30" :data="data" :read_only="read_only")
      table-element(@action="action" number="31" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="20" :data="data" :read_only="read_only")
      table-element(@action="action" number="21" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="93" :data="data" :read_only="read_only")
      table-element(@action="action" number="94" :data="data" :read_only="read_only")

    div(style="width: 2%;")
    div(style="width: 10%; margin-left: 8px;")
      div(style="transform: rotate(90deg); margin-top: -35px;"): table-element(@action="action" number="10" :data="data" :mirrored="true" :read_only="read_only")
      div(style="transform: rotate(90deg); margin-top: -45px;"): table-element(@action="action" number="11" :data="data" :mirrored="true" :read_only="read_only")
  .row
    div(style="width: 10%; margin-left: -30px;")
    div(style="width: 1%;")
    div(style="width: 8%; margin-left: 8px;")
      table-element(@action="action" number="92" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="72" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="62" :data="data" :read_only="read_only")
      table-element(@action="action" number="63" :data="data" :read_only="read_only")

    div(style="width: 8%;")
      table-element(@action="action" number="52" :data="data" :read_only="read_only")
      table-element(@action="action" number="53" :data="data" :read_only="read_only")
    div(style="width: 10%;")
      table-element(@action="action" number="2" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="42" :data="data" :read_only="read_only")
      table-element(@action="action" number="43" :data="data" :read_only="read_only")

    div(style="width: 8%;")
      table-element(@action="action" number="32" :data="data" :read_only="read_only")
      table-element(@action="action" number="33" :data="data" :read_only="read_only")

    div(style="width: 8%;")
      table-element(@action="action" number="22" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="95" :data="data" :read_only="read_only")
    div(style="width: 10%; margin-left: 15px;")





  div(style="transform: rotate(55deg);top: 130px;position: relative;right: 325px;")
    .row
      div(style="width: 8%;"): table-element(@action="action" number="100" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="101" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="102" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="103" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="104" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="105" :data="data" :read_only="read_only")
    .row(style="left: 8%;position: relative;")
      div(style="width: 8%;"): table-element(@action="action" number="111" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="110" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="109" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="108" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="107" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="106" :data="data" :read_only="read_only")
  div(style="transform: rotate(-55deg);top: -655px;position: relative;right: -650px;")
    .row
      div(style="width: 8%;"): table-element(@action="action" number="205" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="204" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="203" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="202" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="201" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="200" :data="data" :read_only="read_only")
    .row(style="left: -8%;position: relative;")
      div(style="width: 8%;"): table-element(@action="action" number="206" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="207" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="208" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="209" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="210" :data="data" :read_only="read_only")
      div(style="width: 8%;"): table-element(@action="action" number="211" :data="data" :read_only="read_only")
  .row(style="position: relative;top: -350px;")
    div(style="width: 35%;")
    div(style="width: 8%;")
      table-element(@action="action" number="300" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="301" :data="data" :read_only="read_only")
    div(style="width: 8%;")
      table-element(@action="action" number="302" :data="data" :read_only="read_only")
  .row(style="position: relative;top: -350px;")
    div(style="width: 32%;")
    div(style="width: 10%;")
      table-element(@action="action" number="303" :data="data" :read_only="read_only")
    div(style="width: 10%;")
      table-element(@action="action" number="304" :data="data" :read_only="read_only")
    div(style="width: 10%;")
      table-element(@action="action" number="305" :data="data" :read_only="read_only")   


</template>

<script>
  import TableElement from "./TableElement.vue"
export default {
  components: {
    TableElement
  },
  props: [
    "data",
    "read_only"
  ],
  data () {
    return {
    }
  },
  mounted () {
  },
  watch: {
  },
  computed: {
  },
  methods: {
    action(ticket){
      this.$emit("action", ticket)
    }
  }
}
</script>

<style lang="scss">
</style>